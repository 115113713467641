import './App.css';
import {
 Route,
 Routes,
 useNavigate,
} from "react-router-dom";
import Login from './pages/login/Login';
import AuthLayout from './components/layouts/AuthLayout';
import AppLayout from './components/layouts/AppLayout';
import RouteGuard from './components/routes/RouteGuard';
import TagList from './pages/tag/TagList';
import MediaList from './pages/media/MediaList';
import ProductList from 'pages/product/ProductList';
import Ability from 'pages/ability/Ability';
import useAuth from 'hooks/authentication/useAuth';
import GameList from 'pages/ability/game/GameList';
import LoadingIndicator from 'components/shared/LoadingIndicator';
import { useEffect } from 'react';


function App() {

  const auth = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!auth.isLoggedIn){
      navigate('/login');
    }
  }, [auth.isLoggedIn])

  return (
    <>
    <LoadingIndicator/>
    <Routes>
      <Route element={<RouteGuard />}>
        <Route path='/' element={<AppLayout></AppLayout>}></Route>
        <Route path='/tag' element={<AppLayout title='Tag'><TagList></TagList></AppLayout>}></Route>
        <Route path='/media' element={<AppLayout title='Média'><MediaList></MediaList></AppLayout>}></Route>
        <Route path='/product' element={<AppLayout title='Termék'><ProductList></ProductList></AppLayout>}></Route>
        <Route path='/ability' element={<AppLayout title='Tematikus tevékenység'><Ability></Ability></AppLayout>}></Route>
        <Route path='/games' element={<AppLayout title='Fejlesztő játékok'><GameList></GameList></AppLayout>}></Route>
      </Route>
      <Route path='/login' element={<AuthLayout><Login></Login></AuthLayout>}></Route>
    </Routes></>
  );
}

export default App;
