import React from 'react';

const AuthLayout = (props) => {

    return (
        <div>{props.children}</div>
    )
    
}

export default AuthLayout;