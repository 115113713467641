import { Box } from "@mui/material";
import { ThreeDots } from "react-loader-spinner";
import { usePromiseTracker } from "react-promise-tracker";

const LoadingIndicator = () => {
  const { promiseInProgress } = usePromiseTracker();

  return (
    promiseInProgress && <Box
        sx={{
          position: "absolute",
          width: "100vw",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ThreeDots color="#ea2c6d" height="100" width="100" />
      </Box>
  );
};

export default LoadingIndicator;
