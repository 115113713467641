import api from 'hooks/api/api';

// Generated by https://quicktype.io

export interface Product {
    id:              number;
    title:           string;
    description:     string;
    content:         string;
    source:          string;
    attributes_data: null;
    webshop_url:     string;
    created_at:      string;
    updated_at:      string;
    tags:            number[];
    media:           number[];
    banner:          number[];
}


const GET_PRODUCT_LIST = '/api/product/filter';
const SHOW_PRODUCT = '/api/product/show';
const POST_PRODUCT = '/api/product/create';
const UPDATE_PRODUCT = '/api/product/update';

const useProduct = () => {

    const getProductList = (page = 1, searchTerm = null): Promise<any> => {
      
        return api.get<any>(GET_PRODUCT_LIST, {page, search_text: searchTerm}).then((resp) => {
            return resp.getFirstData();
        });

    }

    const getProductListData = (searchParam = ""): Promise<Product[]> => {
        return api.get<any>(GET_PRODUCT_LIST, {search_text: searchParam}).then((resp) => {
            return resp.getFirstData().data;
        });
    }

    const showProduct = (id): Promise<Product> => {
      
        return api.get<any>(SHOW_PRODUCT + '/' + id).then((resp) => {
            const rawData = resp.getFirstData();
            const product =  rawData as Product;
            product.tags = rawData.tags.map((tag) => tag.id);
            product.media = rawData.media.map((media) => media.id);
            product.banner = rawData.banner && rawData.banner.length > 0 ? rawData.banner[0].id : null;
            return product;
        });

    }

    const postProduct = (params: FormData):  Promise<any> => {

        return api.post<void>(POST_PRODUCT, params).then((resp) => {
            return resp.getFirstData();
        });
    }

    const updateProduct = (id: number, formData: FormData):  Promise<any> => {

        return api.post<void>(UPDATE_PRODUCT + '/' + id, formData).then((resp) => {
            return resp.getFirstData();
        });
    }


    return {getProductList, postProduct, showProduct, updateProduct, getProductListData};

}

export default useProduct;